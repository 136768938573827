import get from 'lodash.get'

import { Stage, Step, Field, Operation, RelatedField, Section } from './types'

const relatedFieldsOperator = {
  [Operation.Eq]: (field: RelatedField, value: string) =>
    `${field.value}` == `${value}`,
  [Operation.Or]: (field: RelatedField<string[]>, value: string) =>
    field.value.some((v) => v === value),
  [Operation.Range]: (field: RelatedField<number[]>, value: string) => {
    const [min, max] = field.value
    return parseInt(value, 10) >= min && parseInt(value, 10) <= max
  },
  [Operation.Exists]: (_field: RelatedField, value: string) =>
    value !== undefined,
}

export const getStageFields = (stage: Stage, values: any) =>
  stage.steps.reduce(
    (acc, cur) => [...acc, ...getStepFields(cur, values)],
    [] as Field[]
  )

export const relatedFieldMatch = (
  relatedField: RelatedField,
  value: string
) => {
  const fn =
    relatedFieldsOperator[
      relatedField.operation as keyof typeof relatedFieldsOperator
    ]
  return fn?.(relatedField, value) ?? true
}

// This function returns all related fields from a section
// based on related field rules
export const getRelatedFields = ({
  fields = [],
  state = {},
}: {
  fields: Field[]
  state: any
}) =>
  fields?.filter(
    (field) =>
      field.relatedFields?.every((relatedField) =>
        relatedFieldMatch(relatedField, get(state, relatedField.fieldName))
      ) ?? true
  ) ?? []

export const getSectionsFields = (sections: Section[]) =>
  sections.reduce(
    (acc, cur) => [...acc, ...(cur?.fields ?? [])],
    [] as Field[]
  ) ?? []

export const getStepFields = (step: Step, formValue?: unknown): Field[] => {
  const fields = getSectionsFields(step.sections)

  return getRelatedFields({ fields, state: formValue })
}

export const getInitialValues = (
  stages: Stage[],
  initialStageIndex: number,
  initialStepIndex: number
) => {
  const stage = stages[initialStageIndex]

  return {
    stages,
    stageIndex: initialStageIndex,
    stage,
    stepIndex: initialStepIndex,
    step: stage?.steps[initialStepIndex],
  }
}

export type StepOperation = 'NEXT' | 'PREVIOUS' | 'STAGE'

export const getStep = (operation: StepOperation) => (
  formState: any,
  stages: Stage[],
  stageIndex: number,
  currentStepIndex: number
): {
  step: Step
  stepIndex: number
  stageIndex: number
  stage: Stage
} => {
  let stepIndex = currentStepIndex
  if (operation === 'NEXT') {
    stepIndex += 1
  }
  if (operation === 'PREVIOUS') {
    stepIndex -= 1
  }
  const currentStep = stages[stageIndex]?.steps[stepIndex]

  if (currentStep) {
    if (currentStep?.relatedFields) {
      const isRelatedFieldsValid = currentStep.relatedFields.every((field) =>
        relatedFieldMatch(field, get(formState, field.fieldName))
      )

      if (isRelatedFieldsValid) {
        return {
          step: currentStep,
          stepIndex,
          stageIndex,
          stage: stages[stageIndex],
        }
      }

      return getStep(operation)(formState, stages, stageIndex, stepIndex)
    }

    return {
      step: currentStep,
      stepIndex,
      stage: stages[stageIndex],
      stageIndex,
    }
  }

  const newStageIndex = operation === 'NEXT' ? stageIndex + 1 : stageIndex - 1
  const newStepIndex =
    operation === 'NEXT' ? -1 : stages[newStageIndex]?.steps?.length

  return getStep(operation)(formState, stages, newStageIndex, newStepIndex)
}
