export interface Stage {
  name: string
  title: string
  steps: Step[]
}

export type StepOperation = 'NEXT' | 'PREVIOUS'

export interface Step {
  name: string
  title: string
  description?: string
  sections: Section[]
  relatedFields?: RelatedField[]
  data?: Record<string, any> | null
}

export interface RelatedField<T = any> {
  fieldName: FieldName | string
  operation: Operation | string
  value: T
}

enum FieldName {
  ContractingPartyType = 'contractingPartyType',
  HasGoalOther = 'hasGoalOther',
  InvestorClassification = 'investorClassification',
  IsIncomeEarned = 'isIncomeEarned',
  IsIncomeFromSecurities = 'isIncomeFromSecurities',
  IsIncomeInherited = 'isIncomeInherited',
  IsIncomeOwnEntrepreneurialActivity = 'isIncomeOwnEntrepreneurialActivity',
  IsIncomeRealEstate = 'isIncomeRealEstate',
  IsincomeOtherInvestments = 'isincomeOtherInvestments',
}

export enum Operation {
  Eq = '$eq',
  Or = '$or',
  Range = '$range',
  Exists = '$exists',
}

export interface Section {
  name: string
  title?: string
  description?: string
  relatedFields?: RelatedField[]
  fields?: Field[]
}

export interface Field {
  name: string
  componentType?: string
  data?: FieldData
  type?: string
  label?: string
  options?: Option[]
  placeholder?: string
  validations?: Validation[]
  defaultValue?: string
  relatedFields?: RelatedField[]
  mask?: string
  description?: string
  subFields?: Field[]
}

export interface AvailableNaturalPersonInvestmentAccount {
  investmentAccountId?: string
  countryOfTaxResidence: string
  taxStatus: string
  taxNumber: string
  taxId: string
  taxOffice: string
  firstName: string
  lastName: string
  email: string
  mobilePhoneNumber: string
  birthday: string
  cityOfBirth: string
  citizenship: string
  street: string
  houseNumber: string
  postCode: string
  city: string
  country: string
  investorClassification: string
  jobTitle: string
  taxCollectingReligiousInstitution: string
}
export interface AvailableLegalEntityInvestmentAccount {
  investmentAccountId?: string
  name: string
  foundingDate: string
  addressCo: string
  street: string
  houseNumber: string
  postCode: string
  city: string
  country: string
  registerCourt: string
  registerNumber: string
  taxNumber: string
  authorizedSignatories?: AvailableAuthorizedSignatory[]
  investorClassification: string
  jobTitle: string
}

export interface AvailableAuthorizedSignatory {
  id: any | null
  firstName: string | null
  lastName: string | null
  birthday: any | null
  citizenship: string | null
  cityOfBirth: string | null
  email: string | null
  mobilePhoneNumber: string | null
  street: string | null
  houseNumber: string | null
  postCode: string | null
  city: string | null
  country: string | null
  isUbo: boolean | null
  jobTitle: string | null
  identCase?: any | null
}

export interface TransientAvailableAuthorizedSignatory
  extends AvailableAuthorizedSignatory {
  internalId: any | null
}

export interface InterestRateRange {
  min: number
  interestRate: number
}

export interface FieldData {
  availableNaturalPersonInvestmentAccounts?: AvailableNaturalPersonInvestmentAccount[]
  availableLegalEntityInvestmentAccounts?: AvailableLegalEntityInvestmentAccount[]
  availableAuthorizedSignatories?: AvailableAuthorizedSignatory[]
  interestRateRanges?: InterestRateRange[]
}

export enum Direction {
  Row = 'row',
  Column = 'column',
}

export interface Option {
  value: boolean | string
  label: string
}

export interface Validation<T = any> {
  name: ValidationName | string
  error: string
  value?: T
  conditions?: RelatedField[]
}

export enum ValidationName {
  Date = 'date',
  IsTrue = 'isTrue',
  MinValue = 'minValue',
  Required = 'required',
  Range = 'range',
}
